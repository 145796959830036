import React from "react"
import {
  Button,
  GridBlackTitle,
  GridButtonOnLine,
  GridSpacer,
  Grid5TinyCallout,
} from "@thedgbrt/logology-ui-components"
import PricingPlans from "../Pricing/PlansNew"

const HomepagePricing = () => (
  <>
    <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [4, 11]]}>
      Affordable at every stage
    </GridBlackTitle>
    <GridSpacer desktop={78} mobile={42} tablet={42} type={5} />

    <PricingPlans priceStart="$69" priceGrow="$119" priceEstablish="$799" />

    <GridSpacer desktop={74} mobile={44} tablet={44} type={5} />

    <Grid5TinyCallout colSpans={[[2, 6], [2, 6], [3, 11]]}>
      Only pay for the difference when you upgrade.
    </Grid5TinyCallout>
    {/* <Grid5TinyCallout colSpans={[[2, 6], [2, 6], [3, 11]]}>
      Launch a lot of projects? Get up to 50% off when you buy more than one
      logo per year.
    </Grid5TinyCallout> */}
    <GridButtonOnLine
      desktop={49}
      mobile={58}
      tablet={58}
      gridType={5}
      colStarts={[2, 2, 3]}
      offset="right"
    >
      <Button alternative secondary href="/pricing/">
        see full package details →
      </Button>
    </GridButtonOnLine>
    <GridSpacer desktop={156} mobile={105} tablet={105} type={5} />
  </>
)

export default HomepagePricing
