import React, { useEffect, useState } from "react"
import { LogoHomeCarousel } from "@thedgbrt/logology-ui-components"

const HomepageCarousel = () => {
  let [startLoad, setStartLoad] = useState(false)
  let timeout1

  useEffect(() => {
    // eslint-disable-next-line
    timeout1 = setTimeout(() => {
      setStartLoad(true)
    }, 5000)
  }, [])

  useEffect(
    () => () => {
      clearTimeout(timeout1)
    },
    [] // eslint-disable-line
  )

  return (
    <LogoHomeCarousel
      slideColors={["#3A3A3A", "#7A206A", "#7A4241", "#0F2E8E", "#DC213A"]}
      slideDescs={[
        "The uncommon letterforms show dynamism. A slight of turquoise in the green brings inventiveness.",
        "A rounded sans serif font shows simplicity and kindness. Purple gives a sense of magic.",
        "The geometric structure conveys sharpness. The brown and beige combination gives a sense of warmth.",
        "A sans serif font shows efficiency and modernity. Dark tones conveys the seriousness of the business.",
        "The thick weight adds stature to the brand. Red gives a sense of energy and pleasure.",
      ]}
      slideImgs={[
        [
          "images/carousel_examples/brainbytes/logo-main.png",
          "images/carousel_examples/brainbytes/logo-mockup-web.png",
          "images/carousel_examples/brainbytes/logo-mockup-sticker.png",
          "images/carousel_examples/brainbytes/logo-mockup-phone.png",
          "images/carousel_examples/brainbytes/logo-icon-app.png",
          "images/carousel_examples/brainbytes/logo-mockup-bcards.png",
          "images/carousel_examples/brainbytes/logo-color-font.png",
        ],
        [
          "images/carousel_examples/familiar/logo-main.png",
          "images/carousel_examples/familiar/logo-mockup-web.png",
          "images/carousel_examples/familiar/logo-mockup-sticker.png",
          "images/carousel_examples/familiar/logo-mockup-phone.png",
          "images/carousel_examples/familiar/logo-icon-app.png",
          "images/carousel_examples/familiar/logo-mockup-bcards.png",
          "images/carousel_examples/familiar/logo-color-font.png",
        ],
        [
          "images/carousel_examples/pushpop/logo-main.png",
          "images/carousel_examples/pushpop/logo-mockup-web.png",
          "images/carousel_examples/pushpop/logo-mockup-sticker.png",
          "images/carousel_examples/pushpop/logo-mockup-phone.png",
          "images/carousel_examples/pushpop/logo-icon-app.png",
          "images/carousel_examples/pushpop/logo-mockup-bcards.png",
          "images/carousel_examples/pushpop/logo-color-font.png",
        ],
        [
          "images/carousel_examples/stackreach/logo-main.png",
          "images/carousel_examples/stackreach/logo-mockup-web.png",
          "images/carousel_examples/stackreach/logo-mockup-sticker.png",
          "images/carousel_examples/stackreach/logo-mockup-phone.png",
          "images/carousel_examples/stackreach/logo-icon-app.png",
          "images/carousel_examples/stackreach/logo-mockup-bcards.png",
          "images/carousel_examples/stackreach/logo-color-font.png",
        ],
        [
          "images/carousel_examples/talktalk/logo-main.png",
          "images/carousel_examples/talktalk/logo-mockup-web.png",
          "images/carousel_examples/talktalk/logo-mockup-sticker.png",
          "images/carousel_examples/talktalk/logo-mockup-phone.png",
          "images/carousel_examples/talktalk/logo-icon-app.png",
          "images/carousel_examples/talktalk/logo-mockup-bcards.png",
          "images/carousel_examples/talktalk/logo-color-font.png",
        ],
      ]}
      triggerLoadStart={startLoad}
    />
  )
}

export default HomepageCarousel
