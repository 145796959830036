import React from "react"
import {
  Button,
  GridBlackTitle,
  GridButtonOnLine,
  GridSpacer,
  Grid5Testimonials,
} from "@thedgbrt/logology-ui-components"

const HomepageHIW = () => (
  <>
    <GridBlackTitle gridType={5} colSpans={[[1, 7], [1, 7], [6, 12]]}>
      What founders are saying
    </GridBlackTitle>
    <GridSpacer desktop={58} mobile={40} tablet={40} type={5} />

    <Grid5Testimonials
      testimonial1Desc="The onboarding process was one of the smoothest that I've seen. There were countless options presented that would have been great for TaskFiler."
      testimonial1Author="<p>Kyle Adamo, founder of <a href='https://www.taskfiler.com' target='_blank'>Taskfiler</a></p>"
      testimonial1ImgSrc="images/v2/testimonial-kyle-adamo-color.jpg"
      testimonial1Color="#49BFC0"
      testimonial2Desc="As a solo software developer, it gave me a much-needed head start on the design direction for the rest of the website and applications."
      testimonial2Author="<p>Daniel Rosenblatt, founder of <a href='https://gamifyhq.com' target='_blank'>Gamify</a></p>"
      testimonial2ImgSrc="images/v2/testimonial-daniel-rosenblatt.jpg"
      testimonial2Color="#36579F"
    />

    <GridButtonOnLine
      desktop={65}
      mobile={80}
      tablet={80}
      gridType={5}
      colStarts={[2, 2, 3]}
      offset="right"
    >
      <Button alternative secondary href="/testimonials/">
        see all testimonials →
      </Button>
    </GridButtonOnLine>
    <GridSpacer desktop={156} mobile={105} tablet={105} type={5} />
  </>
)

export default HomepageHIW
