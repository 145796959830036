import React from "react"
import {
  Button,
  GridBlackTitle,
  GridButtonOnLine,
  GridSpacer,
  GridSubtitle,
  Grid5HowItWorks,
} from "@thedgbrt/logology-ui-components"

const HomepageHIW = () => (
  <>
    <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [3, 7]]}>
      How it works
    </GridBlackTitle>
    <GridSubtitle
      gridType={5}
      colSpans={[[3, 11], [3, 11], [3, 11]]}
      className="hide_mobile hide_tablet"
    >
      We automated a complete brand design process in less than 5 minutes.
    </GridSubtitle>
    <GridSpacer desktop={54} mobile={28} tablet={28} type={5} />

    <Grid5HowItWorks
      stepDescs={[
        "Answer 11 deep questions about your startup to determine your brand personality and marketing voice.",
        "We automatically match you with pre-made logo proposals that display the values of your brand.",
        "Pick your favorite design variant, choose colors and font, then download your files right away.",
      ]}
      stepTitles={[
        "Take the brand personality test",
        "Match with logos that fit your values",
        "Choose, customize & download",
      ]}
    />

    <GridButtonOnLine
      desktop={70}
      mobile={46}
      tablet={46}
      gridType={5}
      colStarts={[3, 3, 5]}
      offset="left"
    >
      <Button
        href="/questionnaire/"
        cta
        primary
        className="hide_mobile hide_tablet"
      >
        see the logos that match my brand values →
      </Button>
      <Button href="/questionnaire/" cta primary className="hide_desktop">
        see logos that match my values →
      </Button>
    </GridButtonOnLine>
    <GridSpacer desktop={156} mobile={106} tablet={106} type={5} />
  </>
)

export default HomepageHIW
