import React from "react"
import {
  GridBlackTitle,
  GridSpacer,
  Grid5FeaturesMasonry,
} from "@thedgbrt/logology-ui-components"

const HomepageFeaturesMasonry = () => (
  <>
    <GridBlackTitle gridType={5} colSpans={[[3, 7], [3, 7], [3, 8]]}>
      Take your side-project to the next level.
    </GridBlackTitle>
    <GridSpacer desktop={61} mobile={42} tablet={42} type={5} />
    <Grid5FeaturesMasonry
      items={[
        {
          title: "Stand out from the competition",
          description:
            "In markets with many existing solutions, branding can be the key differentiator that makes you more recognizable.",
        },
        {
          title: "Create an emotional bond with your audience",
          description:
            "A meaningful logo generates more desire and trust in your brand, and increase retention and word-of-mouth.",
        },
      ]}
    />
    <Grid5FeaturesMasonry
      items={[
        {
          title: "Boost your confidence",
          description:
            "A good logo will make your idea feel more tangible. Like dressing-up for a big event, it boosts you to talk and put yourself out there.",
        },
        {
          title: "Look professional to clients and investors",
          description:
            "A good logo gives your project a sense of credibility, especially when you’re a new company or freelancer.",
        },
      ]}
      last
    />
    <GridSpacer desktop={156} mobile={77} tablet={77} type={5} />
  </>
)

export default HomepageFeaturesMasonry
