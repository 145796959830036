import React from "react"
import {
  GridBlackTitle,
  GridSpacer,
  Grid5Feature,
} from "@thedgbrt/logology-ui-components"

const HomepageFeaturesBig = () => (
  <>
    <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [4, 12]]}>
      Just like working with a designer
    </GridBlackTitle>
    <GridSpacer desktop={85} mobile={42} tablet={42} type={5} />
    <Grid5Feature
      description="We automated the brand identity workshop we use with consulting clients. It matches your project with the right logos, colors and fonts."
      title="Follow a proven brand definition process"
      imgSrc="images/v2/illus-meaning-2.png"
      contain
    />
    <GridSpacer desktop={85} mobile={42} tablet={42} type={5} />
    <Grid5Feature
      description="Every logo comes from an original idea by a real designer, started with pen and paper. No generic designs seen everywhere."
      title="Get creative ideas that make you stand out"
      imgSrc="images/v2/illus-creative-2.png"
      left
      alignImageRight
    />
    <GridSpacer desktop={85} mobile={42} tablet={42} type={5} />
    <Grid5Feature
      description="We only show you font and color options that embody your brand values. We also explain the meaning of every symbol, font and color."
      title="Have confidence in the choice you make"
      imgSrc="images/v2/illus-variations.png"
      alignImageRight
      zoomImg
    />
    <GridSpacer desktop={85} mobile={42} tablet={42} type={5} />
    <Grid5Feature
      description="Our designs are simple and easy to remember. They look perfect from tiny app icon to huge billboards, in color and black and white."
      title="Get a design that’s visually perfect"
      imgSrc="images/v2/illus-precision.png"
      left
    />
    <GridSpacer desktop={156} mobile={77} tablet={77} type={5} />
  </>
)

export default HomepageFeaturesBig
