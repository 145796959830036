import React from "react"
import {
  GridBlackTitle,
  GridSpacer,
  Grid5Compare,
} from "@thedgbrt/logology-ui-components"

const HomepageCompare = () => (
  <>
    <GridBlackTitle gridType={5} colSpans={[[2, 7], [2, 7], [5, 10]]}>
      How we Compare
    </GridBlackTitle>
    <GridSpacer desktop={47} mobile={40} tablet={40} type={5} />
    <Grid5Compare
      colTitles={["Logology", "Freelance designer", "Logo generator"]}
      colValues={[
        [
          "circle",
          "circle",
          "circle",
          "circle",
          "on $799 package",
          "$69 – $799",
        ],
        ["circle", "circle", "cross", "cross", "circle", "$800 – $3,000"],
        ["cross", "cross", "circle", "circle", "cross", "$20 – $80"],
      ]}
      rowLabels={[
        "professional designer",
        "brand guidance",
        "ready in minutes",
        "no upfront payment",
        "exclusive ownership",
        "price range",
      ]}
    />
    <GridSpacer desktop={156} mobile={77} tablet={77} type={5} />
  </>
)

export default HomepageCompare
