import React from "react"
import {
  GridBlackTitle,
  GridSpacer,
  Grid5Features3,
} from "@thedgbrt/logology-ui-components"

const HomepageWhy = () => (
  <>
    {" "}
    <GridBlackTitle gridType={5} colSpans={[[2, 6], [2, 6], [2, 6]]}>
      Why Logology
    </GridBlackTitle>
    <GridSpacer desktop={47} mobile={47} tablet={47} type={5} />
    <Grid5Features3
      descriptions={[
        "Every logo and customization choice was crafted by an expert designer. You choose from credible brand proposals only.",
        "It only takes 5 minutes to fill the questionnaire and see your logos. You can then choose, buy and download your favorite right away.",
        "Don’t risk paying in advance for something you won’t like. Only buy a logo if you find one that you want to use.",
      ]}
      titles={["Professional look", "Ready instantly", "No upfront payment"]}
      imgSrcs={[
        "images/v2/illus-landing-professional.png",
        "images/v2/illus-landing-time.png",
        "images/v2/illus-landing-money.png",
      ]}
    />
    <GridSpacer desktop={156} mobile={77} tablet={77} type={5} />
  </>
)

export default HomepageWhy
