import React, { useEffect } from "react"
import {
  Button,
  Grid5Praise,
  GridBox,
  GridHomeIntroWithButton2,
  Grid5FooterCta,
  Grid5LetterDesigner,
  GridSpacer,
  // Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../components/seo"
import HomepageTestimonials from "../components/Homepage/HomepageTestimonials2"
import HomepageCarousel from "../components/Homepage/HomepageCarousel"
import HomepageHIW from "../components/Homepage/HomepageHIW"
import HomepageWhy from "../components/Homepage/HomepageWhy"
import HomepageCompare from "../components/Homepage/HomepageCompare"
import HomepageFeaturesBig from "../components/Homepage/HomepageFeaturesBig"
import HomepageFeaturesMasonry from "../components/Homepage/HomepageFeaturesMasonry"
import HomepagePricing from "../components/Homepage/HomepagePricing"
import { trackSegmentEvent } from "../helpers/segment"
import { trackAmplitude } from "../helpers/amplitude"

const IndexPage = () => {
  useEffect(() => {
    trackSegmentEvent("track", "View homepage")
    trackAmplitude("View homepage")
  }, [])

  return (
    <>
      <SEO title="Logology | Get a brand identity for your startup, in 5 minutes." />

      <GridBox colSpans={[[2, 7], [2, 7], [3, 11]]} type={5} withBgContainer>
        <Title standalone className="mod3">
          Inspiring brand design for your startup, ready to use or to be customized.
        </Title>
      </GridBox>

      <GridHomeIntroWithButton2
        buttonComponent={
          <>
            <Button
              href="/questionnaire/"
              cta
              primary
              className="hide_mobile hide_tablet"
            >
              see the logos that match my brand values →
            </Button>

            <Button cta href="/questionnaire/" primary className="hide_desktop">
              see the logos that match my brand values →
            </Button>
          </>
        }
      >
        We’ve designed a catalog of 500+ logos. Take a brand identity test and
        we’ll instantly match you with the best ones
        <span className="hide_desktop">.</span>
        <span className="hide_mobile hide_tablet">
          , paired with the right fonts &amp; colors.
        </span>
      </GridHomeIntroWithButton2>

      <HomepageCarousel />
      <GridSpacer desktop={87} mobile={75} tablet={75} type={5} />

      <Grid5Praise
        descriptions={[
          "The logos are of higher quality than any others I've seen at the same price point.",
          "We've been blown away by the proactive customer service and logology team.",
          "You’re walked through the process of creating something that really aligns with your brand.",
        ]}
        imgSrcs={[
          "/images/v2/what-is-a-farm-logo-small.png",
          "/images/v2/the-home-dispatch-logo-small.png",
          "/images/v2/melobleep-logo-small.png",
        ]}
        imgDims={[[169, 33], [167, 26], [127, 35]]}
      />
      <GridSpacer desktop={156} mobile={74} tablet={74} type={5} />

      <HomepageHIW />

      <HomepageTestimonials />

      <HomepageWhy />

      <HomepageCompare />

      <HomepageFeaturesBig />

      <HomepagePricing />

      <Grid5LetterDesigner
        content={`
        <p>Hi,<br/>I’m Lucie, and I’ve been designing brands since 2005.</p>
        <p>Your new project is the beginning of something beautiful, an idea that may one day change the world. But in today’s never-ending stream of product launches, it’s hard to get people to notice.</p>
        <p>That’s why you need to connect with them on a deeper level. You need a brand identity that tells your story in a credible way, to increase the desire and trust they have for your product.</p>
        <p>I specialize in visual story telling: the art of conveying emotion through symbols. I’ve refined my user-centric design process by helping startups for 15+ years, and teaching in universities for 10.</p>
        <p>With Logology, you get easy access to the essence of my work. I’d love for you to try it out! You might be 5 minutes away from getting a logo people fall in love with.</p>
        <p>Wishing you the best for your project,</p>
        <p><b>Lucie Baratte,</b><br/>Founder and Art Director of Logology.<br/>-<br/><small>My Twitter: <a href="https://twitter.com/LucieBaratte" target="_blank">@LucieBaratte</a></small></p>
      `}
        title="<b>From:</b> the designer<br/><b>To:</b> startup founders"
        imgAuthorSrc="images/v2/profile-lucie-cropped.jpg"
        imgBgSrcSmall="images/v2/illus-designer-sm.png"
        imgBgSrcBig="images/v2/illus-designer.png"
      />
      <GridSpacer desktop={156} mobile={77} tablet={77} type={5} />

      <HomepageFeaturesMasonry />

      <Grid5FooterCta
        description="That’s all it takes to determine the logos that match the vision of your startup."
        title="Got 5 minutes?"
      >
        <Button
          cta
          href="/questionnaire/"
          primary
          className="hide_mobile hide_tablet"
        >
          see the logos that match my values →
        </Button>
        <Button cta href="/questionnaire/" primary className="hide_desktop">
          logos that match my values →
        </Button>
      </Grid5FooterCta>
    </>
  )
}

export default IndexPage
